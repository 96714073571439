import React, { useState } from "react";

import { Link } from 'react-router-dom';
import Slider from "react-slick";


const Billing = () => {
    const contractNo = document.getElementById('contractNo');
    const postalCode = document.getElementById('postalCode');
    const phoneNumber = document.getElementById('phoneNumber');

    const FORM_STATE = {
        section1: 0,
        section2: 0,
        section3: 0,
        section4: 0,
        section5: 0,
    }

    const [formState, setFormState] = useState(FORM_STATE)

    const updateForm = (sectionValue, sectionValue2 = '') => {
        if (sectionValue2) {
            setFormState({ ...formState, [`${sectionValue}`]: 1, [`${sectionValue2}`]: 0 })
        } else {
            setFormState({ ...formState, [`${sectionValue}`]: 1 })
        }

    }

    const validateContract = () => {
        if (contractNo.value && (postalCode.value || phoneNumber.value)) {
            updateForm('section3');
            document.querySelectorAll('.choice').forEach(element => element.remove())
            contractNo.setAttribute("disabled", true);
            postalCode.setAttribute("disabled", true);
            phoneNumber.setAttribute("disabled", true);
            document.getElementById("validateBtn").remove();
            setTimeout(() => {
                document.getElementById('postalCode2').scrollIntoView({
                    behavior: 'smooth'
                });
            }, 100)



        } else {
            contractNo.focus();
        }
        console.log(formState)

    }

    return (
        <div>


            <section class="page-title bg-1">
                <div class="container">
                    <div class="columns">
                        <div class="column is-12">
                            <div class="block has-text-centered">
                                <h1 class="is-capitalize text-lg">Update Billing</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="contact-form-wrap section">
                <div class="container">

                    <div class="columns is-multiline is-justify-content-center">
                        <div class="column is-10-desktop" style={{ color: "#000" }}>

                            <div class="columns is-multiline">
                                <h5 className="w-100 text-color" style={{ fontWeight: "100" }} >Validate your account by using one of the method below</h5>
                                <span class="choice w-100">
                                    <input name="validationChoice" id="validationChoice1" type="radio" value="validationChoice1" required="true" class="mr-2" />
                                    <label class="form-option-label" for="validationChoice1" onClick={() => updateForm('section1', 'section2')}>Contract Number And Postal Code</label>
                                </span>
                                <span class="choice w-100">
                                    <input name="validationChoice" id="validationChoice2" type="radio" value="validationChoice2" required="true" class="mr-2" />
                                    <label class="form-option-label" for="validationChoice2" onClick={() => updateForm('section2', 'section1')}>Contract Number And Phone Number</label>
                                </span>

                                <div className={formState.section1 === 1 || formState.section2 === 1 ? "" : "hidden"}>
                                    <div className="is-flex">
                                        <div>
                                            <div class="column is-12 pl-0 is-flex is-flex-wrap-wrap"  >
                                                <label class="form-option-label" for="contractNo">Contract Number </label>
                                                <input name="contractNo" id="contractNo" type="text" class="input" placeholder="Contract Number" />
                                            </div>
                                        </div>

                                        <div id="section1" className={formState.section1 === 0 ? "hidden" : ""}>
                                            <div class="column is-12 pl-0 is-flex is-flex-wrap-wrap" >
                                                <label class="form-option-label" for="postalCode">Postal Code </label>
                                                <input name="postalCode" id="postalCode" type="text" class="input" placeholder="Postal Code" />
                                            </div>
                                        </div>

                                        <div id="section2" className={formState.section2 === 0 ? "hidden" : ""}>
                                            <div class="column is-12 pl-0 is-flex is-flex-wrap-wrap">
                                                <label class="form-option-label" for="phoneNumber">Phone Number </label>
                                                <input name="phoneNumber" id="phoneNumber" type="text" class="input" placeholder="Phone Number" />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-main" id="validateBtn" onClick={() => validateContract()}>Validate</button>
                                </div>

                                <div id="section3" className={"mt-5 " + (formState.section3 === 0 ? "hidden" : "")}>
                                    <div className="is-flex">
                                        <div class="column is-12 pl-0 is-flex is-flex-wrap-wrap"  >
                                            <label class="form-option-label" for="streetAddress">Street Address </label>
                                            <input name="streetAddress" id="streetAddress" type="text" class="input" placeholder="Street Address" disabled />
                                        </div>
                                    </div>
                                    <div className="is-flex">
                                        <div class="column is-4 pl-0 is-flex is-flex-wrap-wrap" >
                                            <label class="form-option-label" for="city">City </label>
                                            <input name="city" id="city" type="text" class="input" placeholder="City" disabled />
                                        </div>

                                        <div class="column is-4 pl-0 is-flex  is-flex-wrap-wrap">
                                            <label class="form-option-label" for="province">Province </label>
                                            <input name="province" id="province" type="text" class="input" placeholder="Province" disabled />
                                        </div>

                                        <div class="column is-4 pl-0 is-flex is-flex-wrap-wrap">
                                            <label class="form-option-label" for="postalCode2">Postal Code </label>
                                            <input name="phoneNumber2" id="postalCode2" type="text" class="input" placeholder="Postal Code" disabled />
                                        </div>
                                    </div>

                                    <div class="column is-12 pl-0 is-flex is-flex-wrap-wrap">
                                        <label class="form-option-label text-color" id="propertyCheckLabel">Confirm if your property address is correct? </label><br />
                                        <div class="column is-12 pl-0 is-flex is-flex-wrap-wrap">
                                            <div>Yes <input name="propertyCheck" id="propertyCheck1" type="radio" value="yes" required="true" class="mr-2" onClick={() => updateForm('section5', 'section4')} /></div>
                                            <div>No <input name="propertyCheck" id="propertyCheck2" type="radio" value="no" required="true" class="mr-2" onClick={() => updateForm('section4', 'section5')} /></div>
                                        </div>

                                    </div>

                                    <div id="section4" className={"column is-12 pl-0 is-flex is-flex-wrap-wrap " + (formState.section4 === 0 ? "hidden" : "")}>
                                        <label class="form-option-label" style={{ color: "crimson" }}>Please <Link to="/contact" className="text-color">click here to contact us</Link> to update your property address. </label><br />
                                    </div>

                                    <div id="section5" className={"column is-12 pl-0 is-flex is-flex-wrap-wrap " + (formState.section5 === 0 ? "hidden" : "")}>
                                        <label class="form-option-label text-color">... </label><br />
                                    </div>


                                </div>
                            </div>

                        </div>


                    </div>


                </div>
            </section>





        </div>
    )
}

export default Billing;