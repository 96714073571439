import React from "react";

import { Link } from 'react-router-dom';
import Slider from "react-slick";


const Tank = () => {


    return (
        <div>


            <section class="page-title bg-1">
                <div class="container">
                    <div class="columns">
                        <div class="column is-12">
                            <div class="block has-text-centered">
                                <h1 class="is-capitalize text-lg">Water Tank Heaters</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section service-2 pb-8">
                <div class="container">
                    <div class="columns is-multiline">
                        
                    </div>
                </div>
            </section>

            <section class="cta-2 bg-light">
                <div class="container">
                    <div class="cta-block py-6">
                        <div class="columns is-desktop is-align-items-center ">
                            <div class="column is-8-desktop">
                                <h2 class="mt-3">Entrust your project to our best team of professionals</h2>
                            </div>
                            <div class="column is-4-desktop has-text-right-desktop">
                                <Link to="/contact" class="btn btn-main btn-round-full">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </div>
    )
}

export default Tank;