import React from "react";

import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer section">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-3-widescreen is-6-tablet">
            <div className="widget">
              <div className="logo mb-4">
              <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" style={{maxWidth: "50%"}} />
              </div>
              <p>Your Trusted Partner for Water Heater Rentals, Installation, and Service in the Niagara region.</p>
            </div>
          </div>
          <div className="column is-2-widescreen is-6-desktop is-6-tablet ml-auto">
            <div className="widget">
              <h4 className="is-capitalize mb-4">Company</h4>

              <ul className="list-unstyled footer-menu lh-35">
                <li><Link to="/about">About</Link></li>
                <li><Link to="/products">Products</Link></li>
                <li><Link to="/testimonials">Testimonials</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </div>
          </div>
          <div className="column is-3-widescreen is-6-desktop is-6-tablet">
            <div className="widget">
              <h4 className="is-capitalize mb-4">Support</h4>

              <ul className="list-unstyled footer-menu lh-35">

              <li><a href="#">Rental Agreement</a></li>
                <li><a href="#">Terms & Conditions</a></li>
              </ul>
            </div>
          </div>
          <div className="column is-3-widescreen is-6-desktop is-6-tablet">
            <div className="widget widget-contact">
              <h4 className="is-capitalize mb-4">Get in Touch</h4>
              <h6><a href="mailto:rafi@solowheating.com" > <i className="ti-headphone-alt mr-3"></i>rafi@solowheating.com</a></h6>
              <h6><a href="tel:9053417773" > <i className="ti-mobile mr-3"></i>905-341-7773</a></h6>

              {/* <ul className="list-inline footer-socials mt-5">
                <li className="list-inline-item"><a href="https://www.facebook.com/themefisher"><i className="ti-facebook mr-2"></i></a></li>
                <li className="list-inline-item"><a href="https://twitter.com/themefisher"><i className="ti-twitter mr-2"></i></a></li>
                <li className="list-inline-item"><a href="https://www.pinterest.com/themefisher/"><i className="ti-linkedin mr-2 "></i></a></li>
              </ul> */}
            </div>
          </div>
        </div>

        <div className="footer-btm py-4 mt-6">
          <div className="columns">
            <div className="column is-6-widescreen">
              <div className="copyright">
                Copyright &copy; Rafi Siddique. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
