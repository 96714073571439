import React from "react";
import { useEffect } from "react";


import { useLocation } from 'react-router-dom';

const Contact = () => {

    let subject;

    useEffect(() => {
        if (window.location.search.indexOf('callback') > 0) {
            document.getElementById('name').scrollIntoView({
                behavior: 'smooth'
            });
            subject = "Request a Callback";
        
        }
    }, []);

    const handleMessage = (e) => {
        e.preventDefault();

        let myHeaders = new Headers();

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let name = document.getElementById('name').value;
        let phone = document.getElementById('phone').value;
        let email = document.getElementById('email').value;
        let subject = document.getElementById('subject').value;
        let message = document.getElementById('message').value;

        fetch(`https://comfortrentalsystems.com/api/sendMessage?name=${name}&phone=${phone}&message=${message}&email=${email}&subject=${subject}&type=${window.location.search.indexOf('callback') > 0 ? 'callback' : 'message'}`, requestOptions)
            .then(response => response.json())
            .then(function (result) {
                if (result.status === 'ok') {
                    document.getElementById('messages').innerHTML = `<div class="success-msg">
                    <i class="fa fa-check"></i>
                    Your message has been sent! We will get in touch with you as soon as possible.
                  </div>`;

                } else {
                    document.getElementById('messages').innerHTML = `<div class="error-msg">
                    <i class="fa fa-check"></i>
                    There seems to be something missing in the form, please correct and try again.
                  </div>`;

                }
            })
            .catch(error => console.log('error', error));



    }

    return (
        <div>


            <section class="page-title bg-1">
                <div class="container">
                    <div class="columns">
                        <div class="column is-12">
                            <div class="block has-text-centered">
                                <h1 class="is-capitalize text-lg">Get In Touch</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="contact-form-wrap section">
                <div class="container">
                    <div class="columns is-multiline">
                        <div class="column is-12">
                            <span class="text-color">Send a message</span>
                            <h3 class="text-md mb-5">Contact Form</h3>
                        </div>
                    </div>

                    <div class="columns is-multiline">
                        <div class="column is-8-desktop">
                            <form id="contact-form" class="contact__form" action="#">
                                <div class="columns is-multiline">
                                    <div class="column is-12">
                                        <input name="name" id="name" type="text" class="input" placeholder="Your Name *" />
                                    </div>
                                    <div class="column is-12">
                                        <input name="phone" id="phone" type="text" class="input" placeholder="Phone Number *" />
                                    </div>
                                    <div class="column is-12">
                                        <input name="email" id="email" type="email" class="input" placeholder="Email Address (optional)" />
                                    </div>

                                    <div class="column is-12">
                                        <input name="subject" id="subject" type="text" class="input" placeholder="Your Subject" value={subject} />
                                    </div>
                                    <div class="column is-12">
                                        <textarea name="message" id="message" class="input" placeholder="Your Message">
                                            {useLocation().search.indexOf('callback') > 0
                                                ? "I would like to learn more about the Enbridge Open Bill Program and how it will affect me. Please call me back."
                                                : ""
                                            }
                                        </textarea>
                                    </div>
                                    <div class="column is-12">
                                        <button class="btn btn-main" id="submit" onClick={(e) => handleMessage(e)} name="submit">Send Message</button>
                                        <div id="messages"></div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="column is-4-desktop">
                            <div class="short-info">
                                <ul class="list-unstyled">
                                    <li>
                                        <h5>Call Us</h5>
                                        <a href="tel:9053417773" className="text-color">+1.905-341-7773</a>
                                    </li>
                                    <li>
                                        <h5>Email Us</h5>
                                        <a className="text-color" href="mailto:rafi@solowheating.com">rafi@solowheating.com</a>
                                    </li>
                                    <li>
                                        <h5>Location</h5>
                                        <a className="text-color" href="https://maps.app.goo.gl/pxqtF3dzE9JiooCR7" target="_blank">St. Catharines ON L2S 3R9</a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div class="columns is-multiline">
                        <div class="column is-12">
                            <div class="google-map mt-90 mb-6">
                                {/* <iframe id="map" width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=-79.47784423828126%2C43.06838615478111%2C-79.10705566406251%2C43.28820233071705&amp;layer=mapnik" ></iframe> */}
                                <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1455.079254189276!2d-79.27594166101956!3d43.16419684917664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d3519a35a3f7e7%3A0x26b048d2c2995837!2sSt.%20Catharines%2C%20ON%20L2S%203R9!5e0!3m2!1sen!2sca!4v1696668937743!5m2!1sen!2sca" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                            </div>
                        </div>
                    </div>

                </div>
            </section>





        </div>
    )
}

export default Contact;