import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";

import Slider from "react-slick";

import products from '../data/tankless.json'


const Home = () => {

    const [initialModal, setInitialModal] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const isMobile = width <= 768;

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const settings2 = {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const setModal = () => {

        sessionStorage.setItem("showModal", "1");
        setInitialModal(1);

    }

    const navigate = useNavigate();

    useEffect(() => {

        if (sessionStorage.getItem("showModal") === "1") {
            setInitialModal(1);
        }

        if(window.location.pathname === "/ddd") {
            navigate("/")
        }

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <div>

            {initialModal === 0
                ?
                <div class="modal-video" tabindex="-1" role="dialog">
                    <div class="modal-video-body">
                        <div class="modal-video-inner">
                            <div class="modal-video-movie-wrap" style={{ background: "white", padding: "30% 5%", paddingTop: "5%", height: isMobile ? "100%" : "0" }}>
                                <button class="modal-video-close-btn js-modal-video-dismiss-btn" aria-label="Close the dialogue by clicking here" style={{ cursor: "pointer" }} onClick={() => setModal()}></button>
                                <div>
                                    <h3 class=" mb-4 mt-2">Action Required for Customers Billed Through Enbridge</h3>
                                    <p>We have been notified by Enbridge Gas that their Open Bill Program is ending, which means you will no longer be eligible for electronic billing. Click the secure link below to sign up for our pre-authorized payment program in order to avoid service interruption or late payment charges.</p>
                                    <Link to="/contact?callback" class={"btn btn-main " + (isMobile ? "" : "mt-5")} style={isMobile ? { display: "block", width: "75%", marginTop: "0.75rem" } : {}}>Request a Callback<i class="fa fa-angle-right ml-2"></i></Link>
                                    <a href="https://www.enbridgegas.com/residential/my-account/understanding-bill/open-bill" target="_blank" class={"btn btn-solid-border " + (isMobile ? "" : "ml-5 mt-5")} style={isMobile ? { display: "block", width: "75%", marginTop: "0.75rem" } : {}}>Learn More</a>
                                    {isMobile && <a href="#" class="btn btn-solid-border mt-3" onClick={() => setModal()}>Close</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <div></div>
            }
            <section className="banner is-flex is-align-items-center">
                <div className="banner-img-part"></div>
                <div className="container">
                    <div className="columns">
                        <div className="column is-8-widescreen is-10-desktop">
                            <div className="block">
                                <span className="is-uppercase text-sm letter-spacing ">Reliable service that you can trust</span>
                                <h2 className="my-4">Comfort Rental Systems</h2>
                                <p className="mb-6">We're here to help you pick the right equipment for your water needs. Specializing in Water Heater Rentals, Sales and Services for Niagara Residents and Businesses.</p>

                                <Link to="/contact" className="btn btn-main">Book an Appointment<i className="fa fa-angle-right ml-2"></i></Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section about bg-light">
                <div className="container">
                    <div className="columns is-multiline">
                        <div className="column is-4-widescreen is-6-tablet">
                            <div className="about-item">
                                <div className="icon">
                                    <i className="ti-infinite"></i>
                                </div>

                                <div className="content">
                                    <h4 className="mt-3 mb-3">Endless Supply</h4>
                                    <p className="mb-5">With our tankless water heaters, you never run out of hot water.</p>

                                    {/* <a href="#"> Read More </a> */}
                                </div>
                            </div>
                        </div>

                        <div className="column is-4-widescreen is-6-tablet">
                            <div className="about-item">
                                <div className="icon">
                                    <i className="ti-money"></i>
                                </div>
                                <div className="content">
                                    <h4 className="mt-3 mb-3">Better Investment</h4>
                                    <p className="mb-5">Our tankless water heaters lasts twice as long as tanks.</p>
                                    {/* <a href="#"> Read More </a> */}
                                </div>
                            </div>
                        </div>

                        <div className="column is-4-widescreen is-6-tablet">
                            <div className="about-item">
                                <div className="icon">
                                    <i className="ti-check"></i>
                                </div>
                                <div className="content">
                                    <h4 className="mt-3 mb-3">Efficiency</h4>
                                    <p className="mb-5">Our tankless water heaters save you money by consuming less energy.</p>
                                    {/* <a href="#"> Read More </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="section portfolio pb-0">
                <div className="container">
                    <div className="columns mb-0">
                        <div className="column is-8-desktop pb-0">
                            <div className="heading">
                                <h2 className="mb-5">Choose from a range of efficient water heaters</h2>
                                <p>Explore our extensive selection of high-efficiency water heating solutions, designed to cater to your unique needs and preferences. Our diverse range includes both tank and tankless water heaters, each carefully selected for their exceptional performance, energy efficiency, and reliability.</p>
                                <Link to="/products" class="btn btn-solid-border mt-3">View More <i class="fa fa-angle-right ml-2"></i></Link>
                            </div>
                        </div>
                    </div>
                    
                    <Slider className="columns is-desktop portfolio-gallery p-0 mb-5" {...settings2}>
                        {products.slice(0, 16).map((product) => (
                            <div class="column is-4-widescreen is-3-desktop is-4-tablet">
                                <div class=" product-item is-relative">
                                    <Link to="/products">
                                        <img src={product.thumbnail_url} style={{ width: "400px", height: "400px", objectFit: "contain" }} class="w-100" />
                                        <h3 class="text-color">{product.properties[0].values[0].name}</h3>
                                    </Link>
                                </div>
                            </div>
                            ))}
                        {/* <div className="column is-4-widescreen is-6-desktop">
                            <div className="portflio-item is-relative mb-4">
                                <a href="project-details.html">
                                    <img src={process.env.PUBLIC_URL + '/images/portfolio/products/1.jpeg'} alt="" className="w-100" />
                                    <div className="overlay-item"><i className="ti-link"></i></div>

                                    <div className="portfolio-item-content">
                                        <h3 className="mb-0 text-white">V53DeN</h3>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="column is-4-widescreen is-6-desktop">
                            <div className="portflio-item is-relative mb-4">
                                <a href="project-details.html">
                                    <img src={process.env.PUBLIC_URL + '/images/portfolio/products/2.jpeg'} alt="" className="w-100" />
                                    <div className="overlay-item">
                                        <i className="ti-link"></i>
                                    </div>
                                    <div className="portfolio-item-content">
                                        <h3 className="mb-0 text-white">RU130iN</h3>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="column is-4-widescreen is-6-desktop">
                            <div className="portflio-item is-relative mb-4">
                                <a href="project-details.html">
                                    <img src={process.env.PUBLIC_URL + '/images/portfolio/products/3.jpeg'} alt="" className="w-100" />

                                    <div className="overlay-item">
                                        <i className="ti-link"></i>
                                    </div>
                                    <div className="portfolio-item-content">
                                        <h3 className="mb-0 text-white">V94XiN</h3>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="column is-4-widescreen is-6-desktop">
                            <div className="portflio-item is-relative mb-4">
                                <a href="project-details.html">
                                    <img src={process.env.PUBLIC_URL + '/images/portfolio/products/4.jpeg'} alt="" className="w-100" />

                                    <div className="overlay-item">
                                        <i className="ti-link"></i>
                                    </div>
                                    <div className="portfolio-item-content">
                                        <h3 className="mb-0 text-white">RL94eN</h3>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="column is-4-widescreen is-6-desktop">
                            <div className="portflio-item is-relative mb-4">
                                <a href="project-details.html">
                                    <img src={process.env.PUBLIC_URL + '/images/portfolio/products/5.jpeg'} alt="" className="w-100" />

                                    <div className="overlay-item">
                                        <i className="ti-link"></i>
                                    </div>
                                    <div className="portfolio-item-content">
                                        <h3 className="mb-0 text-white">RE199iP</h3>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="column is-4-widescreen is-6-desktop">
                            <div className="portflio-item is-relative mb-4">
                                <a href="project-details.html">
                                    <img src={process.env.PUBLIC_URL + '/images/portfolio/products/6.jpeg'} alt="" className="w-100" />

                                    <div className="overlay-item">
                                        <i className="ti-link"></i>
                                    </div>
                                    <div className="portfolio-item-content">
                                        <h3 className="mb-0 text-white">RU130eP</h3>
                                    </div>
                                </a>
                            </div>
                        </div> */}
                    </Slider>
                </div>

            </section>

            <section className="section cta">
                <div className="container">
                    <div className="columns is-desktop">
                        <div className="column is-5-widescreen is-7-desktop">
                            <div className="cta-content bg-white rounded">
                                <h3 className="mb-5">Ready to find the perfect water heater for your<span className="text-color-primary"> home?</span> </h3>
                                <p className="mb-30">Book an appointment with our experts today to discuss your specific needs, get personalized recommendations, and secure the most efficient and cost-effective solution for your hot water needs. Don't wait, take the first step toward endless hot water comfort now!</p>

                                <Link to="/contact" className="btn btn-main">Book an Appointment<i className="fa fa-angle-right ml-2"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="section testimonial">
                <div className="container">
                    <div className="columns is-desktop">
                        <div className="column is-8-desktop">
                            <div className="heading">
                                <h2 className="mb-5">What they say about us</h2>
                                <p>Discover the experiences and satisfaction of our valued customers. Read their testimonials to learn how our water heater rental services have improved their lives and homes. </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="columns is-multiline">
                        <Slider className="column is-12 testimonial-wrap" {...settings}>
                            <div className="testimonial-block">
                                <p>
                                    I couldn't be happier with my decision to rent a tankless water heater from this company. It's been a game-changer for my family. We now enjoy endless hot water!
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Sarah T.</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial-block">
                                <p>
                                    I was skeptical about switching to a tankless water heater, but Rafi made the transition seamless. They patiently answered all my questions, and the installation was quick.
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>David R.</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial-block">
                                <p>
                                    The tank water heater I rented from them has been incredibly reliable. With a family of five, we always had enough hot water, even during busy mornings. I'm a satisfied customer!
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Jennifer M.</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial-block">
                                <p>
                                    I recently moved into a new home and needed a water heater. Rafi came highly recommended by a friend. I'm glad I listened because their tank water heater has been excellent.
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Robert H.</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial-block">
                                <p>
                                    Rafi not only provided me with the perfect tankless solution but also handled the installation professionally. It's been a fantastic upgrade for my home.
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Laura B.</h6>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home;