import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useLayoutEffect } from 'react'

import Footer from './components/footer';

import Navbar from './components/navbar';
import Home from './pages/home';
import About from './pages/about';
import Testimonials from './pages/testimonials';
import Contact from './pages/contact';
import Products from './pages/products';
import Tank from './pages/tank';
import Tankless from './pages/tankless';
import Combi from './pages/combi';
import Billing from './pages/billing';

const root = ReactDOM.createRoot(document.getElementById('root'));
const allRoutes = ['/about', '/testimonials', '/contact', '/products', '/tankless-water-heaters', '/water-tank-heaters', '/combi-boilers', '/billing'];

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.querySelector('body').scrollIntoView({
        behavior: 'smooth'
    });

  }, [location.pathname]);
  return children
} 

const Component = ({title}) => {
  return (
    <div>
      <p style={{paddingTop: '150vh'}}>{title}</p>
    </div>
  )
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div>
        <Navbar allRoutes={allRoutes} />
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/products" element={<Products />} />
            <Route path="/tankless-water-heaters" element={<Tankless />} />
            <Route path="/water-tank-heaters" element={<Tank />} />
            <Route path="/combi-boilers" element={<Combi />} />
            <Route path="/billing" element={<Billing />} />
            

            <Route path="*" element={<Home />} />

          </Routes>
        </Wrapper>
        <Footer />
      </div>
    </BrowserRouter>
  </React.StrictMode>
);