import React from "react";

import { Link } from 'react-router-dom';
import Slider from "react-slick";


const Products = () => {


    return (
        <div>


            <section class="page-title bg-1">
                <div class="container">
                    <div class="columns">
                        <div class="column is-12">
                            <div class="block has-text-centered">
                                <h1 class="is-capitalize text-lg">Products</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section service-2 pb-0">
                <div class="container">
                    <div class="columns is-desktop is-justify-content-center">
                        <div class="column is-10-desktop">
                            <div class="heading has-text-centered">
                                <h2>Discover a Wide Range of <br /><span class="text-color">High-Quality Water Heating Products</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="columns is-align-items-center">
                        <div class="column is-6-desktop">
                            <div class="service-img">
                                <img src={process.env.PUBLIC_URL + '/images/service/service-1.jpg'} style={{ height: "300px", objectFit: "cover", objectPosition: "center" }} class="w-100" />
                            </div>
                        </div>
                        <div class="column is-6-desktop">
                            <div class="service-info ">
                                <h3 class=" mb-5 mt-3">
                                    Portfolio of installed rental water heaters and other assets
                                </h3>
                                <p>When it comes to your commercial or residential projects, where speed, quality, and perfection are paramount, you've arrived at the ideal destination. At Comfort Rental Systems Inc., we take pride in our highly trained and experienced technicians who are at your service 24/7. Our mission is to transform your home into a haven of comfort and convenience, ensuring a hassle-free experience every step of the way</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-padding is-relative">
                        <div class="columns is-align-items-center">
                            <div class="column is-6-desktop">
                                <div class="service-info">
                                    <h3 class=" mb-5 mt-3">
                                        Why need Rental Water Heaters?
                                    </h3>
                                    <ul style={{ fontSize: "20px", color: "#000" }} class="list-unstyled mt-5 mb-6">
                                        <li><i class="ti-check mr-4 text-color"></i>HVAC Certified</li>
                                        <li><i class="ti-check mr-4 text-color"></i>A Comfort Rental Water Heater provides outstanding value and complete customer care</li>
                                        <li><i class="ti-check mr-4 text-color"></i>Affordable monthly rates</li>
                                        <li><i class="ti-check mr-4 text-color"></i>Peace of mind, hassle free hot water with no extra maintenance or parts charges</li>
                                        <li><i class="ti-check mr-4 text-color"></i>24/7 emergency service</li>
                                        <li><i class="ti-check mr-4 text-color"></i>Free installation and removal of your existing tank</li>
                                        <li><i class="ti-check mr-4 text-color"></i>No hidden charges</li>
                                        <li><i class="ti-check mr-4 text-color"></i>Free 636 Venting</li>
                                        <li><i class="ti-check mr-4 text-color"></i>ENERGY STAR® rated Tanks</li>
                                    </ul>

                                </div>
                            </div>
                            <div class="column is-6-desktop">
                                <div class="service-img">
                                    <img src={process.env.PUBLIC_URL + '/images/service/service-3.jpg'} alt="" class="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <section class="section case-study">
                <div class="container">
                    <div class="columns is-justify-content-center">
                        <div class="column is-8-desktop">
                            <div class="case-study-content has-text-centered mb-6">
                                <h2 class="mb-5">What We Offer</h2>
                            </div>
                        </div>
                    </div>

                    <div class="case-timeline">
                        <div class="case-timeline-divider"></div>
                        <div class="case-timeline-dot"></div>

                        <div class="columns is-align-items-center">
                            <div class="column is-6-desktop">
                                <div class="case-img ">
                                    <img src={process.env.PUBLIC_URL + '/images/about/process-3.jpg'} alt="" class="w-100" />
                                </div>
                            </div>
                            <div class="column is-6-desktop">
                                <div class="case-content">
                                    <h4 class="mb-4">Tankless Water Heaters</h4>
                                    <p>With the continuous increase in natural gas prices, the decision to invest in a tankless water heater becomes increasingly appealing. Opting for a tankless solution offers the potential for significant energy savings and a continuous supply of hot water, making it a wise and cost-effective choice.</p>
                                    {/* <a class="btn btn-main" href="/contact">View More <i class="fa fa-angle-right ml-2"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="case-timeline">
                        <div class="case-timeline-divider"></div>
                        <div class="case-timeline-dot"></div>

                        <div class="columns is-align-items-center">
                            <div class="column is-6-desktop">
                                <div class="case-img">
                                    <img src={process.env.PUBLIC_URL + '/images/about/process-2.jpg'} alt="" class="w-100" />
                                </div>
                            </div>
                            <div class="column is-6-desktop">
                                <div class="case-content">
                                    <h4 class="mb-4">Water Tank Heater</h4>
                                    <p>Conventional water heaters typically store and distribute several gallons of hot water at once. For instance, a 50-gallon tank can efficiently provide hot water for simultaneous activities like showering, dishwashing, and laundry, but only for a limited duration, typically around half an hour.</p>
                                    {/* <a class="btn btn-main" href="/contact">View More <i class="fa fa-angle-right ml-2"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="case-timeline">
                        <div class="case-timeline-divider"></div>
                        <div class="case-timeline-dot"></div>

                        <div class="columns is-align-items-center">
                            <div class="column is-6-desktop">
                                <div class="case-img">
                                    <img src={process.env.PUBLIC_URL + '/images/about/process-1.jpg'} alt="" class="w-100" />
                                </div>
                            </div>
                            <div class="column is-6-desktop">
                                <div class="case-content">
                                    <h4 class="mb-4">Combi Boiler</h4>
                                    <p>The combined type, commonly referred to as a Combi system, offers both effective space heating and the supply of domestic hot water (DHW), all within a compact footprint that is both easy to install and maintain. The combi boiler is engineered to provide both space heating and hot water from a single unit.</p>
                                    {/* <a class="btn btn-main" href="/contact">View More <i class="fa fa-angle-right ml-2"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section class="cta-2 bg-light">
                <div class="container">
                    <div class="cta-block py-6">
                        <div class="columns is-desktop is-align-items-center ">
                            <div class="column is-8-desktop">
                                <h2 class="mt-3">Entrust your project to our best team of professionals</h2>
                            </div>
                            <div class="column is-4-desktop has-text-right-desktop">
                                <Link to="/contact" class="btn btn-main btn-round-full">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </div>
    )
}

export default Products;