import React from "react";

import { Link } from 'react-router-dom';
import Slider from "react-slick";


const Testimonials = () => {


    return (
        <div>


            <section class="page-title bg-1">
                <div class="container">
                    <div class="columns">
                        <div class="column is-12">
                            <div class="block has-text-centered">
                                <h1 class="is-capitalize text-lg">Testimonials</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="video-block section">
                <div class="container">
                    <div class="columns is-desktop is-justify-content-center">
                        <div class="column is-10-desktop">
                            <div class="heading has-text-centered">
                                <h2>From Satisfied Clients to Success Stories <br /><span class="text-color">See What Our Clients Have to Say</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="columns">
                        <div style={{ flex: "auto" }} className="column is-3-desktop is-6-tablet">
                            <div className="testimonial-block">
                                <p style={{height:"300px"}}>
                                    I couldn't be happier with my decision to rent a tankless water heater from this company. It's been a game-changer for my family. We now enjoy endless hot water!
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Sarah T.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ flex: "auto" }} className="column is-3-desktop is-6-tablet">
                            <div className="testimonial-block">
                                <p style={{height:"300px"}}>
                                    I was skeptical about switching to a tankless water heater, but Rafi made the transition seamless. They patiently answered all my questions, and the installation was quick.
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>David R.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: "auto" }} className="column is-3-desktop is-6-tablet">
                            <div className="testimonial-block">
                                <p style={{height:"300px"}}>
                                    The tank water heater I rented from them has been incredibly reliable. With a family of five, we always had enough hot water, even during busy mornings. Their service has left me truly content and pleased!
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Jennifer M.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div style={{ flex: "auto" }} className="column is-3-desktop is-6-tablet">
                            <div className="testimonial-block">
                                <p style={{height:"300px"}}>
                                    I recently moved into a new home and needed a water heater. Rafi came highly recommended by a friend. I'm glad I listened because their tank water heater has been excellent.
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Robert H.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: "auto" }} className="column is-3-desktop is-6-tablet">
                            <div className="testimonial-block">
                                <p style={{height:"300px"}}>
                                    Rafi not only provided me with the perfect tankless solution but also handled the installation professionally. It's been a fantastic upgrade for my home.
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Laura B.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: "auto" }} className="column is-3-desktop is-6-tablet">
                            <div className="testimonial-block">
                                <p style={{height:"300px"}}>
                                    I was looking for a reliable water heater rental service, and I'm glad I found Rafi. He made the entire process effortless. The tankless water heater they installed has been fantastic!
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Michael S.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div style={{ flex: "auto" }} className="column is-3-desktop is-6-tablet">
                            <div className="testimonial-block">
                                <p style={{height:"300px"}}>
                                    Rafi truly lives up to his name. He saved me time, money, and hassle when I needed a new water heater. The installation was quick, and the after sales service has been great.
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Emily P.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: "auto" }} className="column is-3-desktop is-6-tablet">
                            <div className="testimonial-block">
                                <p style={{height:"300px"}}>
                                    I appreciate the attention to detail that Rafi brought to my rental experience. He carefully assessed my needs and recommended the perfect tank water heater for my home.
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Daniel M.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: "auto" }} className="column is-3-desktop is-6-tablet">
                            <div className="testimonial-block">
                                <p style={{height:"300px"}}>
                                    Rafi exceeded my expectations in every way. From the initial consultation to the installation of my tankless water heater, their team demonstrated a high level of expertise and dedication.
                                </p>

                                <div className="client-info is-flex is-align-items-center">
                                    <div className="info">
                                        <h6>Susan L.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section class="cta-2 bg-light">
                <div class="container">
                    <div class="cta-block py-6">
                        <div class="columns is-desktop is-align-items-center ">
                            <div class="column is-8-desktop">
                                <h2 class="mt-3">Entrust your project to our best team of professionals</h2>
                            </div>
                            <div class="column is-4-desktop has-text-right-desktop">
                                <Link to="/contact" class="btn btn-main btn-round-full">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Testimonials;