import React from "react";
import { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import products from '../data/tankless.json'


const Tankless = () => {
    const [product, setProduct] = useState({});
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const setModal = (value) => {

        if (value === "") {
            setProduct({})
        } else {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch("https://app.salsify.com/catalogs/api/catalogs/bdf6cab8-b514-48d9-bfa2-bbc12d49e048/products/" + value, requestOptions)
                .then(response => response.json())
                .then(result => setProduct(result))
                .catch(error => console.log('error', error));



        }

    }

    const hideModal = () => {
        setProduct({})
    }
    return (
        <div>
            {Object.keys(product).length
                ?
                <div class="modal-video" style={{cursor: "default"}} tabindex="-1" role="dialog" >
                    <div class="modal-video-body" style={{ maxWidth: isMobile ? "100%" : "85%" }}>
                        <div class="modal-video-inner">
                            <div class="modal-video-movie-wrap" style={{ overflow: "auto", background: "white", padding: "0% 5%", height: isMobile ? "100%" : "80%" }}>
                                <i class="ti-close" aria-label="Close the dialogue by clicking here" style={{ fontSize: "2rem", color: "#000", right: "2rem", top: "2rem", position: "absolute", cursor: "pointer" }} onClick={() => hideModal()}></i>
                                <div class="columns">
                                    <div class="column lg-6">
                                        <div class="case-img">
                                            <a href={product.profile_asset.large_url} target="_blank"><img src={product.profile_asset.large_url} alt="" class="w-100" /></a>
                                        </div>
                                        <div>
                                            {product.asset_properties.map((asset) => (
                                                <a class="btn btn-solid-border m-1" target="_blank" href={asset.values[0].download_url.substr(asset.values[0].download_url.length - 3, 3) === "mp4" ? asset.values[0].large_url : asset.values[0].download_url}>{asset.name.replace("Product Info Document - ","")}</a>
                                            ))}
                                        </div>

                                    </div>

                                    <div class="column lg-6 column md-8">
                                        <div class="case-content pl-4 mt-4 mt-lg-0">
                                            <h3 class="text-color mb-3">{product.name}</h3>

                                            <p style={{ color: "#000" }}>{product.summary.description.values[0].name}</p>

                                            <ul class="list-unstyled project-info-list pl-0">
                                                {product.property_groups[1].properties.map((property) => (
                                                    <li><strong>{property.name}: </strong>{property.values[0].name}</li>
                                                ))}
                                            </ul>
                                            <Link to="/contact" className="btn btn-main">Call us for more info <i className="fa fa-angle-right ml-2"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <div></div>
            }

            <section class="page-title bg-1">
                <div class="container">
                    <div class="columns">
                        <div class="column is-12">
                            <div class="block has-text-centered">
                                <h1 class="is-capitalize text-lg">Tankless Water Heaters</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section service-2 pb-8">
                <div class="container">
                    <div class="columns is-multiline">
                        {products.map((product) => (
                            <div class="column is-3-desktop is-4-tablet">
                                <div class=" product-item is-relative">
                                    <a href="#" onClick={() => setModal(product.properties[1].values[0].id)}>
                                        <img src={product.thumbnail_url} style={{ width: "400px", height: "400px", objectFit: "contain" }} class="w-100" />
                                        <h3 class="text-color">{product.properties[0].values[0].name}<i class="ti-link"></i></h3>
                                    </a>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>

            <section class="cta-2 bg-light">
                <div class="container">
                    <div class="cta-block py-6">
                        <div class="columns is-desktop is-align-items-center ">
                            <div class="column is-8-desktop">
                                <h2 class="mt-3">Entrust your project to our best team of professionals</h2>
                            </div>
                            <div class="column is-4-desktop has-text-right-desktop">
                                <Link to="/contact" class="btn btn-main btn-round-full">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </div>
    )
}

export default Tankless;