import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const toggleNavbar = () => {
    document.querySelector('.navbar-burger').classList.toggle('is-active');
    document.querySelector('.navbar-menu').classList.toggle('is-active');
}

const mobileToggle = () => {
    if(window.outerWidth < 1024){
        let navDropdown = document.querySelector('.navbar-dropdown');

        if ( navDropdown.style.display === 'none' || navDropdown.style.display === '' ) {
            console.log('1')
            navDropdown.style.display = 'block';
        } else {
            console.log('2')
            navDropdown.style.display = 'none';
        }
    }
}

const Navbar = (props) => {

    return (
        <div id="navbar" className={"navigation py-4 "+ (props.allRoutes.indexOf(useLocation().pathname) > -1 ? "navbar-expand-lg header-padding nav-text-white" : "")}>
            <div className="container">
                <nav className="navbar">
                    <div className="navbar-brand">
                        <Link className="navbar-item" to="/">
                            <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" />
                        </Link>
                        <button role="button" className="navbar-burger burger" onClick={toggleNavbar} data-hidden="true" data-target="navigation">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </button>
                    </div>

                    <div className="navbar-menu" id="navigation">
                        <ul className="navbar-start ml-auto">
                            <li className="navbar-item">
                                <Link className="navbar-link" to="/">Home</Link>
                            </li>

                            <li className="navbar-item">
                                <Link className="navbar-link" to="/about">About Us</Link>
                            </li>

                            <li className="navbar-item">
                                <Link to="/products" className="navbar-link" onClick={mobileToggle}>Products</Link>
                                {/* <div className="navbar-dropdown">
                                    <Link className="navbar-item" to="/water-tank-heaters">Water Tank Heater</Link>
                                    <Link className="navbar-item" to="/tankless-water-heaters">Tankless Water Heater</Link>
                                    <Link className="navbar-item" to="/combi-boilers">Combi Boilers</Link>
                                </div> */}
                            </li>

                            <li className="navbar-item">
                                <Link className="navbar-link" to="/testimonials">Testimonials</Link>
                            </li>

                            <li className="navbar-item">
                                <Link className="navbar-link" to="/contact">Contact Us</Link>
                            </li>
                        </ul>
                        <ul className="navbar-end ml-0">
                            <li className="navbar-item">
                                <Link to="/contact" className="btn btn-main">Book an Appointment <i className="fa fa-angle-right ml-2"></i></Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>

    )
}

export default Navbar
