import React from "react";

import { Link } from 'react-router-dom';


const About = () => {



    return (
        <div>


            <section class="page-title bg-1">
                <div class="container">
                    <div class="columns">
                        <div class="column is-12">
                            <div class="block has-text-centered">

                                <h1 class="is-capitalize text-lg">About Us</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="video-block section bg-light">
                <div class="container">
                    <div class="columns is-desktop is-justify-content-center">
                        <div class="column is-10-desktop">
                            <div class="heading has-text-centered">
                                <h2>Celebrate quality excellence in <br /><span class="text-color"> Water Heater Rentals and Service</span></h2>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-desktop is-align-items-center">
                        <div class="column is-6-desktop">
                            <div class="feature-list">
                                <h2 class="mb-5">Serving Niagara region since 2008</h2>
                                <p>Comfort Rental Systems specializes in comprehensive water heater rental solutions, encompassing installation, servicing, and maintenance of water heating systems. Book an appointment with us today for:</p>



                                <ul class="list-unstyled mt-5 mb-6">
                                    <li><i class="ti-check mr-4"></i>Reliable Hot Water, Always</li>
                                    <li><i class="ti-check mr-4"></i>Cost-Effective Water Heater Solutions</li>
                                    <li><i class="ti-check mr-4"></i>Swift and Precise Installation Services</li>
                                </ul>
                                <Link to="/contact" class="btn btn-main">Book an Appointment<i class="fa fa-angle-right ml-3"></i></Link>

                            </div>
                        </div>

                        <div class="column is-6-desktop">
                            <div class="video-img">
                                <img src={process.env.PUBLIC_URL + '/images/about/img-34.jpg'} alt="" class="w-100" style={{ maxHeight: "600px", objectFit: "cover", objectPosition: "center" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section service">
                <div class="container">
                    <div class="columns">
                        <div class="column is-10-desktop">
                            <div class="heading" style={{ marginBottom: 0 }}>
                                <h2 class="mb-5">Why choose <br />Comfort Rental Systems</h2>
                                <p>We understand that selecting the right partner for your water heater rental needs is a crucial decision. What sets us apart is our unwavering commitment to excellence, backed by over a decade of dedicated service to the Niagara region.</p>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-multiline is-align-items-center">
                        <div class="column is-4-widescreen is-6-tablet">
                            <div class="service-item-list">
                                <i class="ti-world"></i>
                                <p>Wide Range of Water Heater Options</p>
                            </div>

                            <div class="service-item-list">
                                <i class="ti-user"></i>
                                <p>24/7 Customer Support</p>
                            </div>
                        </div>

                        <div class="column is-4-widescreen is-6-tablet">
                            <div class="service-item-list">
                                <i class="ti-thumb-up"></i>
                                <p>Reliable Hot Water Supply</p>
                            </div>
                            <div class="service-item-list">
                                <i class="ti-star"></i>
                                <p>Customer-Centric Approach</p>
                            </div>
                        </div>


                        <div class="column is-4-widescreen is-6-tablet">
                            <div class="service-item-list">
                                <i class="ti-medall"></i>
                                <p>Experienced and Certified Technicians</p>
                            </div>
                            <div class="service-item-list">
                                <i class="ti-money"></i>
                                <p>Cost-Effective Solutions</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <section class="cta-2 bg-light">
                <div class="container">
                    <div class="cta-block py-6">
                        <div class="columns is-desktop is-align-items-center ">
                            <div class="column is-8-desktop">
                                <h2 class="mt-3">Entrust your project to our best team of professionals</h2>
                            </div>
                            <div class="column is-4-desktop has-text-right-desktop">
                                <Link to="/contact" class="btn btn-main btn-round-full">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default About;